import { Injectable } from '@angular/core';
import { ColumnRoleValue, ColumnTypeValues, ExperimentsDto, RecExpDto } from '../../../generated-code/logic-core-api';
import { ExperimentsRow } from '../../core/models/experiments-row.model';

@Injectable({
  providedIn: 'root',
})
export class ExperimentsHelperService {
  createRows(experiments: ExperimentsDto) {
    const rows: ExperimentsRow[] = [];
    for (const row of experiments.rows || []) {
      const expRowId = row?.id || '';
      const newRow: ExperimentsRow = { _expRowId: expRowId, notes: row.notes };
      for (const col of experiments.columns || []) {
        const expColId = col?.id || '';
        const value = experiments?.values?.find((v) => v.expColId === expColId && v?.expRowId === expRowId);
        if (value) {
          if (value?.val !== undefined && col.type === ColumnTypeValues.Number) {
            newRow[expColId] = +(value?.val as string);
          } else {
            newRow[expColId] = value.val;
          }
        }
      }
      rows.push(newRow);
    }
    return rows;
  }

  createRowsForRecomendations(experiments: RecExpDto) {
    const rows: ExperimentsRow[] = [];
    for (const row of experiments.rows || []) {
      const recExpRowId = row?.id || '';
      const newRow: ExperimentsRow = { _expRowId: recExpRowId };
      for (const col of experiments.columns || []) {
        const expColId = col?.id || '';
        const precission = col?.precision || 0;
        const value = experiments?.values?.find((v) => v.expColId === expColId && v?.recExpRowId === recExpRowId);
        if (value && value.val !== undefined && value.val !== null) {
          if (col.role === ColumnRoleValue.Response) {
            const std = Math.sqrt(value?.variance || 0);
            const inc = std * 1.96;
            newRow[expColId] =
              `${ExperimentsHelperService.formatNumber(+value.val, precission)} (± ${ExperimentsHelperService.formatNumber(inc, precission)})`;
          } else if (col.type === ColumnTypeValues.Number) {
            newRow[expColId] = `${ExperimentsHelperService.formatNumber(+value.val, precission)}`;
          } else {
            newRow[expColId] = value.val;
          }
        }
      }
      rows.push(newRow);
    }
    return rows;
  }

  static formatNumber(value: number, decimals: number) {
    if (decimals === 0) return Math.round(value);
    const n = Math.pow(10, decimals);
    return Math.round(value * n) / n;
  }
}
